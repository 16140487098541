a {
  color: inherit;
  text-decoration: none;
}

.show-no-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
}

.show-no-scrollbar-auto {
  overflow: auto;
}

.show-no-scrollbar::-webkit-scrollbar,
.show-no-scrollbar::-webkit-scrollbar-thumb,
.show-no-scrollbar-auto::-webkit-scrollbar {
  display: none;
  width: 0;
}
.h-auto {
  height: auto;
}
.separator {
  border-top: 1px solid #e6e6e6;
}
.btn-inside > * {
  cursor: pointer;
}

.pointer-cursor {
  cursor: pointer;
}

.not-allowed-cursor {
  cursor: not-allowed;
}

.default-cursor {
  cursor: default;
}

.icon {
  padding: 4px;
}

.icon:hover {
  background-color: #e6e6e6;
  border-radius: 2px;
}

.dashed-border {
  border: 1px solid;
  border-style: dashed;
}
.editor-design-inactive-area {
  z-index: 1100;
  opacity: 0.5;
  pointer-events: none;
}
.editor-design-ornament-inactive-area {
  z-index: 1100;
  /* opacity: 0.5; */
  pointer-events: none;
  /* box-shadow: 0 0 0 160px rgb(250, 250, 250); */
  /* border-radius: 50%; */
}
.box {
  background: white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.global-drag-line {
  border-color: transparent !important;
}

p {
  font-style: normal;
  font-weight: 500;
  color: #19191a;
  font-size: 16px;
}

.m-0 {
  margin: 0 !important;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}
.fs-28 {
  font-size: 28px;
}

.fw-700 {
  font-weight: 700;
}

.grey100 {
  color: #fafafa;
}

.grey200 {
  color: #f2f2f2;
}

.grey300 {
  color: #e6e6e6;
}

.grey400 {
  color: #cccccc;
}

.grey500 {
  color: #8b8b8c;
}

.grey600 {
  color: #646566;
}

.grey700 {
  color: #505152;
}

.grey800 {
  color: #313233;
}

.grey900 {
  color: #19191a;
}

.bottom-button {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.bottom-button button {
  width: 100%;
}

body.modal-open {
  overflow: hidden;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.p-16px {
  padding: 16px !important;
}
.p-18px {
  padding: 18px !important;
}
.p-10px {
  padding: 10px !important;
}
.text-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.b-r-6px {
  border-radius: 6px;
}
.b-r-4px {
  border-radius: 4px;
}
.grey-border {
  border: 1px solid #e6e6e6;
}
.b-t {
  border-top: 1px solid #e6e6e6;
}
.b-b {
  border-bottom: 1px solid #e6e6e6;
}
.p-y-10 {
  padding: 10px 0;
}
.f-s-14 {
  font-size: 14px;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

@media only screen and (max-width: 1024px) {
  .responsiveness-styles {
    .responsive-text .fs-14 {
      font-size: 10px;
    }
    .fs-16 {
      font-size: 14px;
    }
    .responsive-btn {
      font-size: 12px;
      padding: 6px 5px;
    }
  }
  .responsive-topbar {
    font-size: 12px !important;
  }
  .responsive-drag-and-drop-items {
    flex-wrap: wrap;
  }
}

.discount-price {
  text-decoration: line-through;
}

.float-right {
  float: right;
}

.warning-wrapper {
  color: red;
  margin: 8px;
}

.flex-wrapper {
  position: relative;
  height: 100%;
}

.flex-wrapper.with-padding {
  padding: 10px;
}
.text-align-center {
  text-align: center;
}
.warning-color {
  color: red !important;
}
.green-color {
  color: #29ab51 !important;
}
.amber-color {
  color: #ffbf00 !important;
}
.icon-wrapper {
  padding: 4px;
  border: 1px solid #e6e6e6;
  font-size: 14px;
  border-radius: 6px;
  min-width: 28px;
  min-height: 28px;
}

textarea {
  font-family: 'Satoshi Medium';
  border: 1px solid rgb(230, 230, 230);
  border-radius: 6px;
}
textarea:focus {
  border: 1px solid rgb(230, 230, 230) !important;
  border-radius: 6px !important;
  outline: 0px;
}

.color-section-info {
  padding: 6px 10px;
  color: #1f76cc;
}
.small-dot {
  width: 4px;
  height: 4px;
  background-color: #8b8b8c;
  border-radius: 50%;
}

.bg-white {
  background-color: white;
}

.page-background-grey {
  background-color: #fafafa;
}

.w-50 {
  width: 50%;
}

.sample-order-banner {
  border: 1px solid #3d98f2;
  background-color: #f3f9ff;
  padding: 3px 6px;
  margin-left: 10px;
}

.total-text {
  padding: 12px 14px;
}
.fs-18 {
  font-size: 18px;
}

.icon-button {
  border-bottom: 1px solid #e6e6e6;
  padding: 0 5px 14px;
}

.f-w-700 {
  font-weight: 700;
}
.sample-cancel-modal {
  z-index: 1200;
  position: fixed;
}

.capitalize {
  text-transform: capitalize;
}

.sample-btn-catalog {
  margin-left: calc(91% - 14px);
  margin-top: 12px;
}

.product-logo {
  width: 150px !important;
  height: auto !important;
  img {
    width: 100% !important;
  }
}

.logo-container {
  img {
    width: 100% !important;
  }
}
.sidebar-product-container .logo-container img:first-of-type {
  width: 32px !important;
}

.red-color {
  color: #dc3c36 !important;
}

.capitalize-first-letter {
  text-transform: lowercase;
}

.capitalize-first-letter::first-letter {
  text-transform: capitalize;
}

.personalization-indicator {
  border: 3px solid #3d98f2;
  border-radius: 20%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-align-center {
  text-align: center;
}

.header-action-menu-wrapper .MuiMenuItem-root {
  color: #313233;
  font-family: 'Satoshi Medium';
  font-size: 0.87rem;
  font-style: normal;
  font-weight: 500;
}

.header-action-menu-wrapper .MuiPaper-root {
  box-shadow: none;
  border: 1px solid #e6e6e6;
  border-radius: 0.3rem;
}

